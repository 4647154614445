import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import ReduxState from "../redux/types";
function ProtectedRoute(props: RouteProps) {
  const user = useSelector((state: ReduxState) => state.user);
  return user.isLogged === true ? (
    <Route {...props} />
  ) : (
    <Redirect to="/login" />
  );
}

export default ProtectedRoute;

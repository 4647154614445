import { ApiService } from "./types";
import { SAGIS_CONSTANTS } from "../types";
import {
  IBranch
} from "./types/responseTypes/Branch";
import { UpdatedBranchDetails } from "./types/requestTypes/Branch";
import qs from "qs";
import axios from "axios";

class BranchService extends ApiService {

  public async exportBranchesToExcel(requestBody) {
    const response = await axios.post(`${process.env.REACT_APP_SAGIS_APP_PATH}/brs-sagis/feature/search/export`, requestBody, {
      responseType: "arraybuffer"
    });
    const excelBlob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(excelBlob);
    downloadLink.setAttribute("download", "data.xlsx");

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);

  }

  public async getBranches(requestBody, page?: number, size?: number) {
    const params = qs.stringify({
      page,
      size,
      layerName: SAGIS_CONSTANTS.AYNA_DEPARTMENTS,
      layerGroupName: SAGIS_CONSTANTS.ADMINISTRATION
    });
    const url = `${process.env.REACT_APP_SAGIS_APP_PATH}/brs-sagis/features?${params}`;
    const res = await this.axios.post<IBranch>(url, requestBody);
    return res.data;
  }

  public async getEmployeesByRoleId(id: number) {
    const res = await this.axios.get<any>(`employees/role/${id}`, {});
    return res.data;
  }

  public async branchAction(id: number, action: string) {
    const res = await this.axios.post<UpdatedBranchDetails>(
      `/branches/${id}/${action}`,
      {}
    );
    return res.data;
  }
}

export default BranchService;

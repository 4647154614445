import React from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import ServiceFactory from "../../utils";
import { useSelector } from "react-redux";
import ReduxState from "../../redux/types";
import { useTranslation } from "react-i18next";

const pageName = "header";
const TheHeaderDropdown = () => {
  const { t } = useTranslation();
  const auth = ServiceFactory.getAuthService();
  const Logout = () => {
    auth.logout();
  };
  const user = useSelector((state: ReduxState) => state.user);
  return (
    <CDropdown inNav className="c-header-nav-items mx-2">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <dt className="p-2">{`${user.first_name} ${user.last_name}`}</dt>
        <div className="c-avatar">
          <CImg
            src="/avatars/avatar.png"
            className="c-avatar-img"
            alt="avatar"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {/* <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Account</strong>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-bell" className="mfe-2" />
          Updates
          <CBadge color="info" className="mfs-auto">
            42
          </CBadge>
        </CDropdownItem> */}
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>{t(`${pageName}:settings`)}</strong>
        </CDropdownItem>
        <CDropdownItem to="/profile">
          <CIcon name="cil-user" className="mfe-2" />
          {t(`${pageName}:profile`)}
        </CDropdownItem>
        {/* <CDropdownItem>
          <CIcon name="cil-settings" className="mfe-2" />
          Settings
        </CDropdownItem> */}
        <CDropdownItem divider />
        <CDropdownItem onClick={Logout}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          {t(`${pageName}:log_out`)}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default React.memo(TheHeaderDropdown);

import {
  DraftRouteState,
  RouteCycleType,
  RouteServiceType,
  RouteState,
  RouteType,
} from "../../views/pages/routes/helpers/constants";
import ObjectToExcel from "../helpers/excel";
import i18n from "../locales";
import { ApiService } from "./types";
import { EmptyResponse } from "./types/responseTypes/Auth";
import {
  IAllDraftRoutes,
  IAllRoutes,
  ICreateRoute,
  IGetBriefRoutes,
  IGetDraftRoute,
  IGetDraftRouteMainData,
  IGetDraftRouteShapeData,
  IGetRoute,
  IGetRouteShape,
  IGetRouteList,
  IRoute,
  IAllStopsForDraftRoute,
  IDraftRoute,
  MyDraftRouteType,
} from "./types/responseTypes/Route";
import { toast } from "react-toastify";
import axios from "axios";
let SAGIS_APP_PATH = process.env.REACT_APP_SAGIS_APP_PATH;
export default class RouteService extends ApiService {
  public async exportRoutesToExcel() {
    const { data } = await this.getAllRoutes();
    const modData = this.transformDataForExcel(data, false);
    ObjectToExcel(modData, "route");
  }

  public async exportDraftRoutesToExcel() {
    const { data } = await this.getDraftRoutes();
    const modData = this.transformDataForExcel(data, true);
    ObjectToExcel(modData, "draft-route", "route");
  }

  public async getRoute(id: number) {
    const { data } = await this.axios.get<IGetRoute>(`/routes/route?id=${id}`, {});
    return data;
  }
  public async getRouteFeatureId(id: string) {
    const { data } = await this.axios.get<IGetRoute>(`/routes/route?featureId=${id}`, {});
    return data;
  }
  public async getRouteList(name?,busNumber?,lineLayerName?,department?) {
    const { data } = await this.axios.get<IGetRouteList>(`${SAGIS_APP_PATH}/brs-sagis/bus-line/features`, {name,busNumber, lineLayerName, department});
    return data;
  }

  public async getStopsOfDraftRoute(id: string) {
    try {
      this.disableErrorHandler();
      const res = await this.axios.get<IAllStopsForDraftRoute>(
        `/routes/drafts/${id}/stops`,
        {}
      );
      return res.data;
    } finally {
      this.enableErrorHandler();
    }
  }

  public async getDraftRouteShapeData(id: number) {
    const { data } = await this.axios.get<IGetDraftRouteShapeData>(
      `/routes/drafts/${id}/shape`,
      {}
    );
    return data;
  }

  public async getShapeFromRoute(id: number) {
    const { data } = await this.axios.get<IGetRouteShape>(
      `/routes/shapes/${id}`,
      {}
    );
    return data;
  }

  public async getShapePointsBasedOnStops(
    stops: { lat: number; lng: number }[]
  ) {
    const { data } = await this.axios.post<{ x: number; y: number }[][]>(
      `/routes/shape-points`,
      { stops }
    );
    return data;
  }

  public async getBriefRoutes() {
    const { data } = await this.axios.get<IGetBriefRoutes>(
      "/routes/shapes",
      {}
    );
    return data;
  }

  public async getDraftRoute(id: number) {
    const { data } = await this.axios.get<IGetDraftRoute>(
      `/routes/drafts/${id}`,
      {}
    );
    return data;
  }

  public async getDraftRouteInfo(id: string) {
    try {
      const { data } = await axios.get<IGetDraftRoute>(
        `${SAGIS_APP_PATH}/brs-sagis/bus-line/${id}`,
        {}
      );
      return data;
    } catch (err) {
      toast("Marşrut tapılmadı", {
        type: "warning",
      });
      throw err;
    }
  }

  public async getRoutesInfo(page: number,size: number) {
    const { data } = await this.axios.get<IGetDraftRoute>(
      `/routes/info?page=${page}&size=${size}`,
      {}
    );
    return data;
  }

  public async archiveRoute(id: number) {
    const { data } = await this.axios.post<EmptyResponse>(
      `/routes/${id}/archive`,
      {}
    );
    return data;
  }

  public async unarchiveRoute(id: number) {
    const { data } = await this.axios.post<EmptyResponse>(
      `/routes/${id}/unarchive`,
      {}
    );
    return data;
  }

  public async sendDraftToReview(id: number) {
    const { data } = await this.axios.post<EmptyResponse>(
      `/routes/drafts/${id}/send-to-review`,
      {}
    );
    return data;
  }

  public async discardDraft(id: number) {
    const { data } = await this.axios.post<EmptyResponse>(
      `/routes/drafts/${id}/discard`,
      {}
    );
    return data;
  }

  public async managerDeclineDraft(id: number) {
    const { data } = await this.axios.post<EmptyResponse>(
      `/routes/drafts/${id}/decline-approval`,
      {}
    );
    return data;
  }

  public async managerApproveDraft(id: number) {
   try {
     const { data } = await this.axios.post<EmptyResponse>(
       `/routes/drafts/${id}/approve`,
       {}
     );
     return data;
   } catch (err){
     toast.error("Bu marşrut təsdiqlənib!");
   }
  }

  public async chiefDeclineDraft(id: number) {
    const { data } = await this.axios.post<EmptyResponse>(
      `/routes/drafts/${id}/decline-review`,
      {}
    );
    return data;
  }

  public async chiefApproveDraft(id: number) {
    const { data } = await this.axios.post<EmptyResponse>(
      `/routes/drafts/${id}/review`,
      {}
    );
    return data;
  }

  public async createDraftForExistingRoute(id: number) {
    const { data } = await this.axios.post<{ data: { id: number } }>(
      `/routes/${id}/drafts`,
      {}
    );
    return data;
  }

  public async getAllRoutes() {
    const { data } = await this.axios.get<IAllRoutes>("/routes", {});
    return data;
  }

  public async getDraftRoutes() {
    try {
      this.disableErrorHandler();
      const { data } = await this.axios.get<IAllDraftRoutes>(
        "/routes/drafts",
        {}
      );
      return data;
    } finally {
      this.enableErrorHandler();
    }
  }

  public async createDraftForNewRoute(d: MyDraftRouteType) {
    const { data } = await this.axios.post<ICreateRoute>("/routes/drafts", d);
    return data;
  }

  public async editDraftRouteMainData(id: number, d: MyDraftRouteType) {
    const { data } = await this.axios.patch<ICreateRoute>(
      `/routes/drafts/${id}/main`,
      d
    );
    return data;
  }

  public async getDraftRouteMainData(id: number) {
    const { data } = await this.axios.get<IGetDraftRouteMainData>(
      `/routes/drafts/${id}/main`,
      {}
    );
    return data;
  }

  private transformDataForExcel(
    data: IRoute[] | IDraftRoute[],
    isDraftRoute: boolean
  ) {
    return data.map((route) => ({
      ...route,
      cycle_type_id: this.formatCycleType(route.cycle_type_id),
      type_id: this.formatType(route.type_id),
      state_id: isDraftRoute
        ? this.formatDraftState(route.state_id)
        : this.formatState(
            route.archived_at ? RouteState.Archived : route.state_id
          ),
      service_type_id: this.formatServiceType(route.service_type_id),
      distance: this.formatDistanceToString(route.distance),
      duration: this.formatDurationToString(route.duration),
      headway: `${route.headway / 60} dəqiqə`,
    }));
  }

  private formatState = (id: RouteState): string => {
    if (id === RouteState.Pending) {
      return i18n.t("route:state_pending");
    } else if (id === RouteState.Published) {
      return i18n.t("route:state_published");
    } else if (id === RouteState.Archived) {
      return i18n.t("route:state_archived");
    } else {
      return "Naməlum";
    }
  };

  private formatDraftState = (id: DraftRouteState): string => {
    if (id === DraftRouteState.Draft) {
      return i18n.t("route:draft_state_draft");
    } else if (id === DraftRouteState.InReview) {
      return i18n.t("route:draft_state_in_review");
    } else if (id === DraftRouteState.Reviewed) {
      return i18n.t("route:draft_state_reviewed");
    } else if (id === DraftRouteState.Approved) {
      return i18n.t("route:draft_state_approved");
    } else {
      return "Naməlum";
    }
  };

  private formatType = (id: RouteType): string => {
    if (id === RouteType.IntraCity) {
      return i18n.t("route:city");
    } else if (id === RouteType.International) {
      return i18n.t("route:international");
    } else if (id === RouteType.IntraDistrict) {
      return i18n.t("route:regional");
    } else if (id === RouteType.Intercity ) {
      return i18n.t("route:intercity");
    } else {
      return "Naməlum";
    }
  };

  private formatCycleType = (id: RouteCycleType): string => {
    if (id === RouteCycleType.Circular) {
      return i18n.t("route:rounded");
    } else if (id === RouteCycleType.Linear) {
      return i18n.t("route:two-way");
    } else {
      return "Naməlum";
    }
  };

  private formatServiceType = (id: RouteServiceType): string => {
    if (id === RouteServiceType.Regular) {
      return i18n.t("route:regular");
    } else if (id === RouteServiceType.Seasonal) {
      return i18n.t("route:seasonal");
    } else {
      return "Naməlum";
    }
  };

  private formatDistanceToString = (meters: number): string => {
    if (meters === 0) return "0 km";
    let formattedDistance = meters / 1000;
    if (meters % 1000 >= 100) return `${formattedDistance.toFixed(1)} km`;
    return `${Math.floor(formattedDistance)} km`;
  };

  private formatDurationToString = (minutes: number): string => {
    const mm = minutes % 60;
    const hh = Math.floor(minutes / 60);
    if (hh > 0) {
      if (mm > 0) return `${hh} saat ${mm} dəqiqə`;
      return `${hh} saat`;
    }
    return `${mm} dəqiqə`;
  };
}

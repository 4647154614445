import { ApiService } from "./types";
import { EmptyResponse } from "./types/responseTypes/Auth";
import ObjectToExcel from "../helpers/excel";
import {
  ICountries,
  ICountry,
  IOnlyCities,
  IOnlyCountries,
} from "./types/responseTypes/Country";
import {
  AddCity,
  AddCountry,
  UpdateCity,
  UpdateCountry,
} from "./types/requestTypes/Country";

class CountryService extends ApiService {
  public async updateCountry(data: UpdateCountry) {
    const res = await this.axios.patch<UpdateCountry>("location/country", data);
    return res.data;
  }

  public async updateCity(data: UpdateCity) {
    const res = await this.axios.patch<UpdateCity>("location/city", data);
    return res.data;
  }

  public async createCountry(data: AddCountry) {
    const res = await this.axios.post<EmptyResponse>("location/country", data);
    return res.data;
  }

  public async createCity(data: AddCity) {
    const res = await this.axios.post<EmptyResponse>("location/city", data);
    return res.data;
  }

  public async exportCountriesToExcel() {
    let { data } = await this.getCountries();

    ObjectToExcel(
      data.map((d: ICountry) => {
        return { country_name: d.country_name, city_name: d.city_name };
      }),
      "countries_&_cities"
    );
  }

  public async getCountries() {
    const res = await this.axios.get<ICountries>("location/countries", {});
    return res.data;
  }
  public async getOnlyCountries() {
    const res = await this.axios.get<IOnlyCountries>(
      "location/countries/brief",
      {}
    );
    return res.data;
  }

  public async getOnlyCities(country_id: number) {
    const res = await this.axios.get<IOnlyCities>("location/cities/brief", {
      country_id,
    });
    return res.data;
  }
}

export default CountryService;

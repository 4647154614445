import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18next from "../../../utils/locales/";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CRow,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import ServiceFactory from "../../../utils";
import Loading from "../../../components/Loading";

const initialValues = {
  password: "",
  confirm_password: "",
};

const auth = ServiceFactory.getAuthService();
const ResetPassword = (props: any) => {
  const [clicked, setClick] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(true);
  const [id, setId] = useState<number>(Number(props.match.params.id));
  const [token, setToken] = useState<string>(props.match.params.token);
  const [isCredentialsValid, setCredentialsValid] = useState<boolean>(false);
  const [isUpdateSuccess, setUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    setFetching(true);
    // check if there are params
    if (id && token) {
      auth
        .checkResetPasswordToken({ id, token })
        .then(({ data }) => {
          setCredentialsValid(data.is_valid);
        })
        .catch(() => {
          // TODO: handle error here properly
        })
        .finally(() => {
          setFetching(false);
        });
    }
  }, []);

  const onSubmit = async () => {
    setClick(true);
    setUpdateSuccess(false);

    await auth
      .resetPassword({
        id,
        token,
        password: formik.values.password,
      })
      .then(({ data }) => {
        setUpdateSuccess(true);
      })
      .catch(() => {
        setClick(false);
        setUpdateSuccess(false);
      });
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(i18next.t("login_page:login_alerts:password_required"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        i18next.t("login_page:login_alerts:password_policy")
      ),
    confirm_password: Yup.string()
      .required(i18next.t("login_page:login_alerts:password_required"))
      .oneOf([Yup.ref("password")], i18next.t("reset_password:alert")),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  if (!isFetching) {
    return (
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="5">
              <CCardGroup>
                {isCredentialsValid ? (
                  <CCard
                    className={`p-4 border ${
                      isUpdateSuccess ? "border-success" : "border-info"
                    }`}
                  >
                    <CCardBody>
                      <CForm onSubmit={formik.handleSubmit}>
                        <h1>{i18next.t("reset_password:title")}</h1>
                        {isUpdateSuccess ? (
                          <div>
                            <p style={{ fontSize: 14 }}>
                              {i18next.t("reset_password:success")}
                            </p>
                            <CRow>
                              <CCol xs="6"></CCol>
                              <CCol xs="6" className="text-right">
                                <Link to="/login">
                                  <CButton color="link" className="px-0">
                                    {i18next.t("reset_password:login")}
                                  </CButton>
                                </Link>
                              </CCol>
                            </CRow>
                          </div>
                        ) : (
                          <div>
                            <CLabel className="text-muted">
                              {i18next.t("reset_password:password")}
                            </CLabel>
                            <CInputGroup className="mb-2">
                              <CInputGroupPrepend>
                                <CInputGroupText>
                                  <CIcon name="cil-lock-locked" />
                                </CInputGroupText>
                              </CInputGroupPrepend>
                              <CInput
                                id="password"
                                name="password"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                placeholder={i18next.t(
                                  "reset_password:password"
                                )}
                                autoComplete="new-password"
                              />
                            </CInputGroup>
                            <p style={{ color: "red" }}>
                              {" "}
                              {formik.errors.password
                                ? formik.errors.password
                                : ""}
                            </p>

                            <CLabel className="text-muted">
                              {i18next.t("reset_password:confirm_password")}
                            </CLabel>
                            <CInputGroup className="mb-4">
                              <CInputGroupPrepend>
                                <CInputGroupText>
                                  <CIcon name="cil-lock-locked" />
                                </CInputGroupText>
                              </CInputGroupPrepend>
                              <CInput
                                id="confirm_password"
                                name="confirm_password"
                                type="password"
                                value={formik.values.confirm_password}
                                onChange={formik.handleChange}
                                placeholder={i18next.t(
                                  "reset_password:confirm_password"
                                )}
                                autoComplete="new-password"
                              />
                            </CInputGroup>
                            <p style={{ color: "red" }}>
                              {" "}
                              {formik.errors.confirm_password
                                ? formik.errors.confirm_password
                                : ""}
                            </p>
                            <CRow>
                              <CCol xs="6">
                                <CButton
                                  color="primary"
                                  type="submit"
                                  className="px-4"
                                  disabled={clicked}
                                >
                                  {clicked && <CSpinner size={"sm"} />}
                                  {i18next.t("reset_password:reset")}
                                </CButton>
                              </CCol>
                              <CCol xs="6" className="text-right">
                                <Link to="/login">
                                  <CButton color="link" className="px-0">
                                    {i18next.t("reset_password:login")}
                                  </CButton>
                                </Link>
                              </CCol>
                            </CRow>
                          </div>
                        )}
                      </CForm>
                    </CCardBody>
                  </CCard>
                ) : (
                  <CCard className="p-4 border border-danger">
                    <CCardBody>
                      <h1>{i18next.t("reset_password:title")}</h1>
                      <p style={{ fontSize: 14 }}>
                        {i18next.t("reset_password:fail")}
                      </p>
                      <CRow>
                        <CCol xs="6"></CCol>
                        <CCol xs="6" className="text-right">
                          <Link to="/login">
                            <CButton color="link" className="px-0">
                              {i18next.t("reset_password:login")}
                            </CButton>
                          </Link>
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                )}
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }

  return <Loading isFullScreen={true} />;
};

export default ResetPassword;

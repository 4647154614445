import { TFunction } from "i18next";
import { PermissionMap } from "../../utils/constants";

const ProdSidebarRoutes = (t: TFunction) =>
  [
    {
      _tag: "CSidebarNavItem",
      name: t("sidebar:dashboard"),
      to: "/",
      icon: "cil-home",
    },
    {
      _tag: "CSidebarNavItem",
      name: t("sidebar:monitoring"),
      to: "/monitoring",
      icon: "cil-monitor",
      permission: PermissionMap.PERM_MONITORING,
    },
    {
      _tag: "CSidebarNavDropdown",
      name: t("sidebar:buses"),
      icon: "cil-bus-alt",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:all_buses"),
          to: "/buses",
          icon: "cil-list",
          permission: PermissionMap.PERM_BUS_READ,
        },
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:add_bus"),
          to: "/buses/add",
          icon: "cil-library-add",
          permission: PermissionMap.PERM_BUS_CREATE,
        },
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:brands"),
          to: "/buses/brands",
          icon: "cil-bold",
          permission: PermissionMap.PERM_BUS_MODEL_MANAGEMENT,
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: t("sidebar:drivers"),
      icon: "cil-people",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:all_drivers"),
          to: "/drivers",
          icon: "cil-list",
          permission: PermissionMap.PERM_DRIVER_READ,
        },
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:add_driver"),
          to: "/drivers/add",
          icon: "cil-library-add",
          permission: PermissionMap.PERM_DRIVER_CREATE,
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: t("sidebar:routes"),
      icon: "cil-cursor",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:all_routes"),
          to: "/routes",
          icon: "cil-list",
          permission: PermissionMap.PERM_ROUTE_READ,
        },
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:all_draft_routes"),
          to: "/drafts",
          icon: "cil-library",
          permission: PermissionMap.PERM_DRAFT_ROUTE_READ,
        },
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:add_route"),
          to: "/routes/add",
          icon: "cil-library-add",
          permission: PermissionMap.PERM_DRAFT_ROUTE_CREATE,
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: t("sidebar:carriers"),
      icon: "cil-briefcase",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:all_carriers"),
          to: "/carriers",
          icon: "cil-list",
          permission: PermissionMap.PERM_CARRIER_READ,
        },
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:add_carrier"),
          to: "/carriers/add",
          icon: "cil-library-add",
          permission: PermissionMap.PERM_CARRIER_CREATE,
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: t("sidebar:contracts"),
      icon: "cil-description",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:all_contracts"),
          to: "/contracts",
          icon: "cil-list",
          permission: PermissionMap.PERM_CONTRACT_READ,
        },
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:add_contract"),
          to: "/contracts/add",
          icon: "cil-library-add",
          permission: PermissionMap.PERM_CONTRACT_CREATE,
        },
      ],
    },
    {
      _tag: "CSidebarNavTitle",
      _children: [t("sidebar:manage")],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: t("sidebar:employees"),
      icon: "cil-people",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:all_employees"),
          to: "/employees",
          icon: "cil-list",
          permission: PermissionMap.PERM_EMPLOYEES_READ,
        },
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:add_employee"),
          to: "/employees/add",
          icon: "cil-library-add",
          permission: PermissionMap.PERM_EMPLOYEES_CREATE,
        },
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:permissions_management"),
          to: "/employees/permissions_management",
          icon: "cil-equalizer",
          permission: PermissionMap.PERM_ROLE_MANAGEMENT,
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: t("sidebar:branches"),
      icon: "cil-institution",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:all_branches"),
          to: "/branches",
          icon: "cil-list",
          permission: PermissionMap.PERM_BRANCH_READ,
        }
      ],
    },
    {
      _tag: "CSidebarNavTitle",
      _children: [t("sidebar:map_helpers")],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: t("sidebar:stops"),
      icon: "cil-location-pin",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:all_stops"),
          to: "/stops",
          icon: "cil-list",
          permission: PermissionMap.PERM_STOP_READ,
        },
      ],
    },
    {
      _tag: "CSidebarNavDropdown",
      name: t("sidebar:objects"),
      icon: "cil-warning",
      _children: [
        {
          _tag: "CSidebarNavItem",
          name: t("sidebar:all_objects"),
          to: "/objects",
          icon: "cil-list",
          permission: PermissionMap.PERM_MAP_OBJECT_READ,
        },
      ],
    },
    {
      _tag: "CSidebarNavItem",
      name: t("sidebar:countries"),
      to: "/countries",
      icon: "cil-globe-alt",
      permission: PermissionMap.PERM_COUNTRY_MANAGEMENT,
    },
    {
      _tag: "CSidebarNavTitle",
      _children: [t("sidebar:helpers")],
    },
    {
      _tag: "CSidebarNavItem",
      name: t("sidebar:guide"),
      to: "/guide",
      icon: "cil-book",
    },
  ] as any[];

export default ProdSidebarRoutes;

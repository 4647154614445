import React from "react";
import { CSpinner } from "@coreui/react";

function Loading(props) {
  return (
    <div
      style={{
        display: "flex",
        height: props.isFullScreen === false ? "100%" : "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CSpinner />
    </div>
  );
}

export default Loading;

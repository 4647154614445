import React, { Suspense } from "react";
import { CContainer, CFade } from "@coreui/react";
import { Redirect, Route, Switch } from "react-router-dom";
// routes config
import routes from "../config/route.config";
import Loading from "./Loading";
import RoleProtectedRoute from "./RoleProtectedRoute";

const TheContent = () => {
  return (
    <main className="c-main">
      <CContainer fluid className="h-100">
        <Suspense fallback={<Loading />}>
          <Switch>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    render={(props) => (
                      <CFade className="h-100">
                        {typeof route.permission !== "undefined" ? (
                          <RoleProtectedRoute permission={route.permission}>
                            <route.component {...props} />
                          </RoleProtectedRoute>
                        ) : (
                          <route.component {...props} />
                        )}
                      </CFade>
                    )}
                  />
                )
              );
            })}
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  );
};

export default React.memo(TheContent);

import { ReduxService } from "./redux";
import AuthService from "./api/AuthService";
import StorageService from "./storage";
import EmployeeService from "./api/EmployeeService";
import BusService from "./api/BusService";
import CarrierService from "./api/CarrierService";
import DriverService from "./api/DriverService";
import BranchService from "./api/BranchService";
import CountryService from "./api/CountryService";
import StopService from "./api/StopService";
import RouteService from "./api/RouteService";
import MapObjectService from "./api/MapObjectService";
import MonitoringService from "./api/MonitoringService";
import ContractService from "./api/ContractService";
import MapLayerService from "./api/MapLayerService";

// These should be explicitly exported
// because ApiServices use them but can't access before all import is ok
// and for avoiding recursion
export const reduxService = new ReduxService();
export const storageService = new StorageService();
const stopService = new StopService();
const routeService = new RouteService();
const authService = new AuthService();
const employeeService = new EmployeeService();
const busService = new BusService();
const driverService = new DriverService();
const mapObjectService = new MapObjectService();
const monitoringService = new MonitoringService();
const contractService = new ContractService();
const countryService = new CountryService();
const carrierService = new CarrierService();
const branchService = new BranchService();
const mapService  = new MapLayerService();

const getInstances = {
  getStopService() {
    return stopService;
  },
  getRouteService() {
    return routeService;
  },
  getReduxService() {
    return reduxService;
  },
  getAuthService() {
    return authService;
  },
  getStorageService() {
    return storageService;
  },
  getEmployeeService() {
    return employeeService;
  },
  getBusService() {
    return busService;
  },
  getDriverService() {
    return driverService;
  },
  getMapObjectService() {
    return mapObjectService;
  },
  getMonitoringService() {
    return monitoringService;
  },
  getContractService() {
    return contractService;
  },
  getBranchService() {
    return branchService;
  },
  getCarrierService() {
    return carrierService;
  },
  getCountryService() {
    return countryService;
  },
  getMapService() {
    return mapService;
  }
};

export default getInstances;

import {
  FirstStageState,
  RouteCycleType,
  RouteDays,
  ToRemoteFirstStage,
} from "../constants";
export default class FirstStateLogic {
  private state: FirstStageState | null = null;
  static instance = new FirstStateLogic();

  private constructor() {}

  static getInstance = () => FirstStateLogic.instance;

  public setState = (state: FirstStageState | null) => {
    this.state = state;
  };

  public getState = () => this.state;

  public toRemote = (): ToRemoteFirstStage => {

    if (this.state === null) {
      throw Error();
    }
    return {
      end_time: this.state.end_time,
      buses: this.state.buses,
      branch_name: this.state.branch_name,
      parking_time: this.state.parking_time,
      start_time: this.state.start_time,
      trips: this.state.trips,
      headway: Number(this.state.headway) * 60,
      branch_feature_id: this.state.branch_feature_id,
      code: this.state.number,
      cycle_type_id: this.state.is_round
        ? RouteCycleType.Circular
        : RouteCycleType.Linear,
      start_loc_name: this.state.start_name,
      end_loc_name: this.state.end_name,
      name: this.state.name,
      // draft_stop_time:"",
      route_feature_id: this.state.route_feature_id,
      intertrip_stop_time: this.state.intertrip_stop_time,
      featureId: this.state.featureId,
      on_weekdays: this.state.route_days.includes(RouteDays.Weekdays),
      on_weekends: this.state.route_days.includes(RouteDays.Weekends),
      price: Number(this.state.fee),
      road_desc: this.state.characteristics,
      safe_speed_limit: Number(this.state.max_speed),
      duration:
          Number(this.state.round_duration_mm),
      distance: Number(this.state.round_distance) * 1000,
      service_type_id: Number(this.state.route_months),
      type_id: this.state.type,
      terminal_departure_time_desc: this.state.terminal_departure_time_desc,
      initial_stop_arrival_time_desc: this.state.initial_stop_arrival_time_desc,
      intertrip_stop_time_desc: this.state.intertrip_stop_time_desc,
      intertrip_stop_location_desc: this.state.intertrip_stop_location_desc,
      driver_break_time_desc: this.state.driver_break_time_desc,
      bus_crew_change_desc: this.state.bus_crew_change_desc,
      terminal_arrival_time_desc: this.state.terminal_arrival_time_desc,
      streets: this.state.street_details.map(
        ({ street_info, street_name }) => ({
          name: street_name,
          desc: street_info,
        })
      ),
    } as ToRemoteFirstStage;
  };

  // TODO replace INewRouteData with IData
  public fromRemote = (data: any): FirstStageState => {
    const route_days: RouteDays[] = [];
    data.streets.sort((a, b) => a.sequence - b.sequence);
    if (data.on_weekdays) route_days.push(RouteDays.Weekdays);
    if (data.on_weekdays) route_days.push(RouteDays.Weekends);
    return {
      end_time:
        data.end_time.substring(0, 2) + ":" + data.end_time.substring(3, 5),
      start_time:
        data.start_time.substring(0, 2) + ":" + data.start_time.substring(3, 5),
      number: data.code,
      buses: data.buses,
      trip_groups:data.trip_groups,
      trips:data.trips,
      branch_name: data.branch_name,
      branch_feature_id: data.branch_feature_id,
      headway: (data.headway / 60).toString(),
      is_round: data.cycle_type_id === RouteCycleType.Circular,
      start_name: data.start_loc_name,
      intertrip_stop_time: data.intertrip_stop_time,
      end_name: data.end_loc_name,
      carriers: data.carriers,
      name: data.name,
      route_days,
      fee: data.price.toString(),
      characteristics: data.road_desc,
      parking_time: data.parking_time,
      max_speed: data.safe_speed_limit.toString(),
      round_distance:
        data.cycle_type_id === RouteCycleType.Circular || data.cycle_type_id === RouteCycleType.Linear
          ? (data.distance / 1000).toString()
          : undefined,
      round_duration_hh:
        data.cycle_type_id === RouteCycleType.Circular || data.cycle_type_id === RouteCycleType.Linear
          ? Math.floor(data.duration / 60).toString()
          : undefined,
      round_duration_mm:
        data.cycle_type_id === RouteCycleType.Circular || data.cycle_type_id === RouteCycleType.Linear
          ? (data.duration % 60).toString()
          : undefined,
      route_months: data.service_type_id,
      type: data.type_id,
      terminal_departure_time_desc: data.terminal_departure_time_desc,
      initial_stop_arrival_time_desc: data.initial_stop_arrival_time_desc,
      intertrip_stop_time_desc: data.intertrip_stop_time_desc,
      intertrip_stop_location_desc: data.intertrip_stop_location_desc,
      route_feature_id: data.route_feature_id,
      driver_break_time_desc: data.driver_break_time_desc,
      bus_crew_change_desc: data.bus_crew_change_desc,
      terminal_arrival_time_desc: data.terminal_arrival_time_desc,
      street_details: data.streets.map((street, index) => ({
        // id will be used for key,
        // since in route creation,
        // keys start from zero, so negative keys will never collide
        id: -1 - index,
        street_info: street.street_desc,
        street_name: street.street_name,
      })),
    } as FirstStageState;
  };
}

import { ApiService } from "./types";
import {
  IAllBranchesAndEmployees,
  IAllEmployeesWithoutPagination,
  IEmployee,
  IEmployeeDetails,
  IPermission,
  IRolePermission,
  IRolesPermissions,
  UpdateEmployeeDetails
} from "./types/responseTypes/Employee";

import { EmptyResponse } from "./types/responseTypes/Auth";
import {
  AddEmployee,
  IRolePermissionResponse,
  UpdatedEmployeeDetails,
  UpdatedEmployeeProfile
} from "./types/requestTypes/Employee";
import ObjectToExcel from "../helpers/excel";
import i18n from "i18next";

import { EmployeeStatus, Permission, Role, SAGIS_CONSTANTS } from "../types";
import { IAllBranchInfo } from "./types/responseTypes/Branch";

class EmployeeService extends ApiService {
  public async getProfileDetails() {
    const res = await this.axios.get<IEmployeeDetails>("/user", {});
    return res.data;
  }

  public async updateProfileDetails(data: UpdateEmployeeDetails) {
    const res = await this.axios.patch<UpdatedEmployeeProfile>("/user", data);
    return res.data;
  }

  public async getRolePermissions() {
    const res = await this.axios.get<IRolesPermissions>(
      "/employees/role-permissions",
      {}
    );
    return res.data;
  }

  public async updateEmployeeDetails(data: UpdateEmployeeDetails) {
    const res = await this.axios.patch<UpdatedEmployeeDetails>(
      "/employees",
      data
    );
    return res.data;
  }

  public async employeeAction(id: number, action: string) {
    const res = await this.axios.post<UpdatedEmployeeDetails>(
      `/employees/${id}/${action}`,
      {}
    );
    return res.data;
  }

  public async permissionAction(data: IRolePermission, action: string) {
    const res = await this.axios.post<IRolePermissionResponse>(
      `/employees/role-permissions/${action}`,
      data
    );
    return res.data;
  }

  public async createEmployee(data: AddEmployee) {
    const res = await this.axios.post<EmptyResponse>("/employees", data);
    return res.data;
  }

  // TODO: find better way to write this function
  public async exportEmployeesToExcel() {
    const { data } = await this.getEmployees();

    // change position and status to user friendly format
    // delete non user friendly fields
    data.forEach((e: IEmployee) => {
      e.position = this.getPosition(e.role_id);
      e.status_text = this.getStatusText(e);
      delete e.role_id;
    });

    ObjectToExcel(data, "employee");
  }

  public async getEmployees() {
    const res = await this.axios.get<IAllEmployeesWithoutPagination>(
      "/employees",
      {}
    );
    return res.data;
  }

  public async getBranchesAndEmployees() {
    const res = await this.axios.get<IAllBranchesAndEmployees>(
      "/employees/brief",
      {}
    );
    return res.data;
  }

  public async getAllSagisBranches() {
    const params = { layerName: SAGIS_CONSTANTS.AYNA_DEPARTMENTS, layerGroupName: SAGIS_CONSTANTS.ADMINISTRATION };
    const url = `${process.env.REACT_APP_SAGIS_APP_PATH}/brs-sagis/feature-properties`;
    const res = await this.axios.get<IAllBranchInfo>(url, params);
    return res.data;
  }

  public getStatus(e: IEmployee): EmployeeStatus {
    if (e.archived_at == null) return EmployeeStatus.Active;
    else return EmployeeStatus.Archived;
  }

  public getStatusText(e: IEmployee) {
    if (e.archived_at == null) return i18n.t("employees_service:active");
    else return i18n.t("employees_service:archived");
  }

  public getPosition(id: Role | number | undefined) {
    switch (id) {
      case Role.Admin:
        return i18n.t("employees_service:admin");
      case Role.Manager:
        return i18n.t("employees_service:management");
      case Role.Chief:
        return i18n.t("employees_service:chief");
      case Role.Operator:
        return i18n.t("employees_service:operator");
      case Role.Observer:
        return i18n.t("employees_service:observer");
      default:
        return i18n.t("employees_service:unknown");
    }
  }

  public getRoleText(id: Role | number): string {
    switch (id) {
      case Role.Manager:
        return i18n.t("permissions_page:management");
      case Role.Chief:
        return i18n.t("permissions_page:chief");
      case Role.Operator:
        return i18n.t("permissions_page:operator");
      case Role.Observer:
        return i18n.t("permissions_page:observer");
      case Role.Admin:
        return i18n.t("permissions_page:admin");
      default:
        return "Unknown role, please correct the error!";
    }
  }

  public getPermissionText(p: IPermission) {
    switch (p.id) {
      case Permission.PERM_DASHBOARD:
        return i18n.t("permissions_page:PERM_DASHBOARD");
      case Permission.PERM_BRANCH_ARCHIVE:
        return i18n.t("permissions_page:PERM_BRANCH_ARCHIVE");
      case Permission.PERM_BRANCH_CREATE:
        return i18n.t("permissions_page:PERM_BRANCH_CREATE");
      case Permission.PERM_BRANCH_UPDATE:
        return i18n.t("permissions_page:PERM_BRANCH_UPDATE");
      case Permission.PERM_BRANCH_READ:
        return i18n.t("permissions_page:PERM_BRANCH_READ");
      case Permission.PERM_EMPLOYEES_APPROVE:
        return i18n.t("permissions_page:PERM_EMPLOYEES_APPROVE");
      case Permission.PERM_EMPLOYEES_ARCHIVE:
        return i18n.t("permissions_page:PERM_EMPLOYEES_ARCHIVE");
      case Permission.PERM_EMPLOYEES_CREATE:
        return i18n.t("permissions_page:PERM_EMPLOYEES_CREATE");
      case Permission.PERM_EMPLOYEES_UPDATE:
        return i18n.t("permissions_page:PERM_EMPLOYEES_UPDATE");
      case Permission.PERM_EMPLOYEES_READ:
        return i18n.t("permissions_page:PERM_EMPLOYEES_READ");
      case Permission.PERM_ROLE_MANAGEMENT:
        return i18n.t("permissions_page:PERM_ROLE_MANAGEMENT");
      case Permission.PERM_CARRIER_CREATE:
        return i18n.t("permissions_page:PERM_CARRIER_CREATE");
      case Permission.PERM_CARRIER_UPDATE:
        return i18n.t("permissions_page:PERM_CARRIER_UPDATE");
      case Permission.PERM_CARRIER_READ:
        return i18n.t("permissions_page:PERM_CARRIER_READ");
      case Permission.PERM_DRIVER_CREATE:
        return i18n.t("permissions_page:PERM_DRIVER_CREATE");
      case Permission.PERM_DRIVER_UPDATE:
        return i18n.t("permissions_page:PERM_DRIVER_UPDATE");
      case Permission.PERM_DRIVER_READ:
        return i18n.t("permissions_page:PERM_DRIVER_READ");
      case Permission.PERM_STOP_CREATE:
        return i18n.t("permissions_page:PERM_STOP_CREATE");
      case Permission.PERM_STOP_UPDATE:
        return i18n.t("permissions_page:PERM_STOP_UPDATE");
      case Permission.PERM_STOP_READ:
        return i18n.t("permissions_page:PERM_STOP_READ");
      case Permission.PERM_BUS_CREATE:
        return i18n.t("permissions_page:PERM_BUS_CREATE");
      case Permission.PERM_BUS_UPDATE:
        return i18n.t("permissions_page:PERM_BUS_UPDATE");
      case Permission.PERM_BUS_READ:
        return i18n.t("permissions_page:PERM_BUS_READ");
      case Permission.PERM_BUS_MODEL_READ:
        return i18n.t("permissions_page:PERM_BUS_MODEL_READ");
      case Permission.PERM_BUS_MODEL_MANAGEMENT:
        return i18n.t("permissions_page:PERM_BUS_MODEL_MANAGEMENT");
      case Permission.PERM_COUNTRY_MANAGEMENT:
        return i18n.t("permissions_page:PERM_COUNTRY_MANAGEMENT");
      case Permission.PERM_DRAFT_ROUTE_READ:
        return i18n.t("permissions_page:PERM_DRAFT_ROUTE_READ");
      case Permission.PERM_DRAFT_ROUTE_CREATE:
        return i18n.t("permissions_page:PERM_DRAFT_ROUTE_CREATE");
      case Permission.PERM_DRAFT_ROUTE_UPDATE:
        return i18n.t("permissions_page:PERM_DRAFT_ROUTE_UPDATE");
      case Permission.PERM_ROUTE_READ:
        return i18n.t("permissions_page:PERM_ROUTE_READ");
      case Permission.PERM_ROUTE_ARCHIVE:
        return i18n.t("permissions_page:PERM_ROUTE_ARCHIVE");
      case Permission.PERM_MAP_OBJECT_CREATE:
        return i18n.t("permissions_page:PERM_MAP_OBJECT_CREATE");
      case Permission.PERM_MAP_OBJECT_UPDATE:
        return i18n.t("permissions_page:PERM_MAP_OBJECT_UPDATE");
      case Permission.PERM_MAP_OBJECT_READ:
        return i18n.t("permissions_page:PERM_MAP_OBJECT_READ");
      case Permission.PERM_MONITORING:
        return i18n.t("permissions_page:PERM_MONITORING");
      case Permission.PERM_CONTRACT_CREATE:
        return i18n.t("permissions_page:PERM_CONTRACT_CREATE");
      case Permission.PERM_CONTRACT_UPDATE:
        return i18n.t("permissions_page:PERM_CONTRACT_UPDATE");
      case Permission.PERM_CONTRACT_READ:
        return i18n.t("permissions_page:PERM_CONTRACT_READ");
      case Permission.PERM_CARRIER_DELETE:
        return i18n.t("permissions_page:PERM_CARRIER_DELETE");
      case Permission.PERM_BUS_DELETE:
        return i18n.t("permissions_page:PERM_BUS_DELETE");
      case Permission.PERM_STOP_DELETE:
        return i18n.t("permissions_page:PERM_STOP_DELETE");
      case Permission.PERM_MAP_OBJECT_DELETE:
        return i18n.t("permissions_page:PERM_MAP_OBJECT_DELETE");
      default:
        return "Unknown Permission, please correct the error!";
    }
  }
}

export default EmployeeService;

import { TFunction } from "i18next";
import prod_routes from "./constants/prod_sidebar_routes";
import local_routes from "./constants/local_sidebar_routes";
import dev_routes from "./constants/dev_sidebar_routes";

function Sidebar(t: TFunction) {
  let res = prod_routes(t);
  if (process.env.REACT_APP_NODE_ENV === "local") {
    res = res.concat(local_routes);
  } else if (process.env.REACT_APP_NODE_ENV === "development") {
    res = res.concat(dev_routes);
  }
  return res;
}
export default Sidebar;

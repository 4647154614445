import { ApiService } from "./types";
import { EmptyResponse } from "./types/responseTypes/Auth";
import ObjectToExcel from "../helpers/excel";
import { ContractStatus } from "../types";
import i18n from "i18next";
import {
  ContractDetails,
  IContracts,
  IContract,
} from "./types/responseTypes/Contract";
import {
  AddContract,
  TerminateContract,
  UpdatedContract,
} from "./types/requestTypes/Contract";
import dayjs from "dayjs";
import { IGetActiveCarriersForContractsResponse } from "./types/responseTypes/Carrier";
import { IGetActiveRoutesForContractsResponse } from "./types/responseTypes/Route";

class ContractService extends ApiService {
  public async getContract(id) {
    const res = await this.axios.get<ContractDetails>(`/contracts/${id}`, {});
    return res.data;
  }

  public async exportContractsToExcel() {
    const { data } = await this.getContracts();
    ObjectToExcel(data, "contracts");
  }

  public async getContracts() {
    const res = await this.axios.get<IContracts>("/contracts", {});
    return res.data;
  }

  public async getActiveCarriers() {
    const res = await this.axios.get<IGetActiveCarriersForContractsResponse>(
      "/carriers/brief/contracts",
      {}
    );
    return res.data;
  }

  public async getActiveRoutes() {
    const res = await this.axios.get<IGetActiveRoutesForContractsResponse>(
      "/routes/brief/contracts",
      {}
    );
    return res.data;
  }

  public async updateContract(data, file?) {
    const formData = new FormData();

    formData.append("id", data.id);
    formData.append("expires_at", data.expires_at);
    if (file) formData.append("contract", file);

    const res = await this.fileAxios.patch<UpdatedContract>(
      `/contracts/${data.id}`,
      formData
    );
    return res.data;
  }

  public async createContract(data: AddContract) {
    const res = await this.axios.post<EmptyResponse>("/contracts", data);
    return res.data;
  }

  public async contractAction(
    id: number,
    action: string,
    data?: TerminateContract
  ) {
    const res = await this.axios.patch<UpdatedContract>(
      `/contracts/${id}/${action}`,
      data
    );
    return res.data;
  }

  public getStatus(c: IContract): ContractStatus {
    if (c.terminated_at == null) return ContractStatus.Active;
    else return ContractStatus.Terminated;
  }

  public getStatusText(c: IContract) {
    if (c.terminated_at == null) return i18n.t("contracts_page:active");
    else return i18n.t("contracts_page:terminated");
  }

  public downloadAttachment(id: number): void {
    this.fileAxios
      .get<Blob>(`/contracts/attachment/${id}`, {})
      .then(({ data }) => {
        this.fileDownload(
          data,
          `${i18n.t("contracts_service:contract")}-${dayjs(
            new Date()
          ).valueOf()}.pdf`
        );
      });
  }

  public async uploadAttachment(data, file) {
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("code", data.code);
    formData.append("carrier_id", data.carrier_id);
    formData.append("route_id", data.route_id);
    formData.append("signed_at", data.signed_at);
    formData.append("expires_at", data.expires_at);
    formData.append("contract", file);
    const res = await this.fileAxios.post<any>(`/contracts`, formData);
    return res.data;
  }

  public getBadge = (c: IContract) => {
    switch (c.status) {
      case ContractStatus.Active:
        return "success";
      case ContractStatus.Terminated:
        return "danger";
      default:
        return "primary";
    }
  };

  public getContractDate = (date) => {
    return dayjs(date).format("DD/MM/YYYY");
  };
}

export default ContractService;

import { Role } from "../types";

declare var localStorage: WindowLocalStorage["localStorage"];

interface LoginDetails {
  user_id: number;
  role: Role;
  token: string;
  permissions: string[];
  branch_name: string;
  branch_feature_id: string;
  first_name: string;
  last_name: string;
}

interface StartupDetails {
  user_id: number | null;
  role: Role | null;
  token: string | null;
  permissions: string[] | null;
  branch_name: string | null;
  branch_feature_id: string | null;
  first_name: string | null;
  last_name: string | null;
}

interface UpdateProfile {
  first_name: string;
  last_name: string;
}

export default class StorageService {
  public updateProfile(userData: UpdateProfile) {
    this.setFirstName(userData.first_name);
    this.setLastName(userData.last_name);
  }

  private setAccessToken(token: string) {
    localStorage.setItem("token", token);
  }

  private getAccessToken() {
    return localStorage.getItem("token");
  }

  private removeAccessToken() {
    localStorage.removeItem("token");
  }

  private setPermissions(permissions: any[]) {
    localStorage.setItem("permissions", JSON.stringify(permissions));
  }

  public getPermissions(): any[] | null {
    const permissions = localStorage.getItem("permissions");
    if (permissions !== null) {
      const data = JSON.parse(permissions);
      return Array.isArray(data) ? data : null;
    }
    return null;
  }

  private removePermissions() {
    localStorage.removeItem("permissions");
  }

  private setRole(role: Role) {
    localStorage.setItem("role", role.toString());
  }

  public getRole(): Role | null {
    const role = localStorage.getItem("role");
    if (role !== null) return Number(role);
    return null;
  }

  private removeRole() {
    localStorage.removeItem("role");
  }

  public getBranch(): string | null {
    const branch = localStorage.getItem("branch_feature_id");
    if (branch !== null) return branch;
    return null;
  }

  private setBranch(branch: string) {
    localStorage.setItem("branch_feature_id", branch.toString());
  }

  private removeBranch() {
    localStorage.removeItem("branch_feature_id");
  }

  private getBranchName() {
    const branchName = localStorage.getItem("branch_name");
    if(branchName !== null) return branchName;
    return null;
  }

  private setBranchName(branchName: string) {
    localStorage.setItem("branch_name", branchName.toString());
  }

  private removeBranchName() {
    localStorage.removeItem("branch_name");
  }

  private setUserId(user_id: number) {
    localStorage.setItem("user_id", user_id.toString());
  }

  public getUserId() {
    const user_id = localStorage.getItem("user_id");
    if (user_id !== null) return Number(user_id);
    return null;
  }

  private removeUserId() {
    localStorage.removeItem("user_id");
  }

  private setFirstName(first_name: string) {
    localStorage.setItem("first_name", first_name);
  }

  private removeFirstName() {
    localStorage.removeItem("first_name");
  }

  public getFirstName() {
    return localStorage.getItem("first_name");
  }

  private setLastName(last_name: string) {
    localStorage.setItem("last_name", last_name);
  }

  private removeLastName() {
    localStorage.removeItem("last_name");
  }

  public getLastName() {
    return localStorage.getItem("last_name");
  }

  public login({
    token,
    permissions,
    role,
    user_id,
    branch_name,
    branch_feature_id,
    first_name,
    last_name,
  }: LoginDetails) {
    this.setAccessToken(token);
    this.setPermissions(permissions);
    this.setRole(role);
    this.setUserId(user_id);
    this.setBranch(branch_feature_id);
    this.setBranchName(branch_name);
    this.setFirstName(first_name);
    this.setLastName(last_name);
  }

  public logout() {
    this.removeAccessToken();
    this.removePermissions();
    this.removeRole();
    this.removeUserId();
    this.removeBranch();
    this.removeFirstName();
    this.removeLastName();
  }

  public getStartupDetails(): StartupDetails {
    const role = this.getRole();
    const permissions = this.getPermissions();
    const token = this.getAccessToken();
    const user_id = this.getUserId();
    const branch_name = this.getBranchName();
    const branch_feature_id = this.getBranch();
    const first_name = this.getFirstName();
    const last_name = this.getLastName();
    return {
      role,
      token,
      permissions,
      user_id,
      branch_name,
      branch_feature_id,
      first_name,
      last_name,
    };
  }
}

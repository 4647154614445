import { toast } from "react-toastify";
import i18next from "i18next";
import { AxiosError } from "axios";
const ErrorHandler = (error: AxiosError) => {
  if (error.response) {
    // does error have message
    const err_code = "errors:" + error.response.data.message;
    if (i18next.exists(err_code))
      toast(i18next.t(err_code), {
        type: "error",
      });
    // else
      // toast(i18next.t("errors:fail"), {
      //   type: "error",
      // });
    // if response not available then error probably is network error
  } else if (error.message) {
    if (error.message === "Network Error")
      toast(i18next.t("errors:network_error"), {
        type: "error",
      });
    else
      toast(error.message, {
        type: "error",
      });
  }
};
export default ErrorHandler;

import { ApiService } from "./types";
import { IMapTileLayer } from "./types/responseTypes/MapLayer";

class MapLayerService extends ApiService {

  public async getTileLayers() {
    const { data } = await this.axios.get<IMapTileLayer[]>(`${process.env.REACT_APP_SAGIS_APP_PATH}/brs-sagis/tileLayers`,{})
    return data;
  }
}

export default MapLayerService;
import { UserAction } from "../actions/user";
import { LOGIN_USER, LOGOUT_USER, UPDATE_USER } from "../constants";
import { UserState } from "../types";

export const initialUser: UserState = {
  isLogged: false,
  role: null,
  token: null,
  permissions: null,
  id: null,
  branch_name: null,
  branch_feature_id: null,
  first_name: null,
  last_name: null,
};

export default function (state = initialUser, action: UserAction): UserState {
  switch (action.type) {
    case LOGIN_USER:
      return Object.assign({}, state, action.payload);
    case LOGOUT_USER:
      return Object.assign({}, state, initialUser);
    case UPDATE_USER: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}

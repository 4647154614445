let API_URL = process.env.REACT_APP_API_URL;

export const PermissionMap = {
  PERM_DASHBOARD: "dashboard",
  PERM_BRANCH_ARCHIVE: "branch_archive",
  PERM_BRANCH_CREATE: "branch_create",
  PERM_BRANCH_UPDATE: "branch_update",
  PERM_BRANCH_READ: "branch_read",
  PERM_EMPLOYEES_APPROVE: "employees_approve",
  PERM_EMPLOYEES_ARCHIVE: "employees_archive",
  PERM_EMPLOYEES_CREATE: "employees_create",
  PERM_EMPLOYEES_UPDATE: "employees_update",
  PERM_EMPLOYEES_READ: "employees_read",
  PERM_ROLE_MANAGEMENT: "role_management",
  PERM_CARRIER_CREATE: "carrier_create",
  PERM_CARRIER_UPDATE: "carrier_update",
  PERM_CARRIER_READ: "carrier_read",
  PERM_DRIVER_CREATE: "driver_create",
  PERM_DRIVER_UPDATE: "driver_update",
  PERM_DRIVER_READ: "driver_read",
  PERM_STOP_CREATE: "stop_create",
  PERM_STOP_UPDATE: "stop_update",
  PERM_STOP_READ: "stop_read",
  PERM_BUS_CREATE: "bus_create",
  PERM_BUS_UPDATE: "bus_update",
  PERM_BUS_READ: "bus_read",
  PERM_BUS_MODEL_READ: "bus_model_read",
  PERM_BUS_MODEL_MANAGEMENT: "bus_model_management",
  PERM_COUNTRY_MANAGEMENT: "country_management",
  PERM_DRAFT_ROUTE_READ: "draft_route_read",
  PERM_DRAFT_ROUTE_CREATE: "draft_route_create",
  PERM_DRAFT_ROUTE_UPDATE: "draft_route_update",
  PERM_ROUTE_READ: "route_read",
  PERM_ROUTE_ARCHIVE: "route_archive",
  PERM_MAP_OBJECT_CREATE: "map_object_create",
  PERM_MAP_OBJECT_UPDATE: "map_object_update",
  PERM_MAP_OBJECT_READ: "map_object_read",
  PERM_MONITORING: "monitoring",
  PERM_CONTRACT_CREATE: "contract_create",
  PERM_CONTRACT_UPDATE: "contract_update",
  PERM_CONTRACT_READ: "contract_read",
  PERM_CARRIER_DELETE: "carrier_delete",
  PERM_BUS_DELETE: "bus_delete",
  PERM_STOP_DELETE: "stop_delete",
  PERM_MAP_OBJECT_DELETE: "map_object_delete",
};
export const RegexPatterns = {
  AZE_DASHES_SPACES: /^((?![wW])([A-Za-z\s\-əüöıçşİğƏÜÖĞÇŞ]))+$/,
  AZE_PHONE_NUMBER: /^\(\+994\s(10|50|51|55|70|77|99)\)\s\d{3}-\d{2}-\d{2}$/,
};
export { API_URL };

import { ApiService } from "utils/api/types";
import { IZoomFeatureResponse } from "utils/api/types/responseTypes/Monitoring";

export default class MonitoringService extends ApiService {

  public async getZoomFeatures(requestBody) {
    const { data } = await this.axios.post<IZoomFeatureResponse>(`${process.env.REACT_APP_SAGIS_APP_PATH}/brs-sagis/zoomFeatures`, requestBody)
    return data;
  }
}

import { login, logout } from "../../redux/actions/user";
import store from "../../redux/store/store";
import ReduxState, { UserState } from "../../redux/types";

export class ReduxService {
  public login(state: UserState): void {
    store.dispatch(login(state));
  }

  public getState(): ReduxState {
    return store.getState();
  }

  public logout(): void {
    store.dispatch(logout());
  }
}

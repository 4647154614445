import { ApiService } from "utils/api/types";
import { IAllMapObjects } from "utils/api/types/responseTypes/MapObject";
import { MapObjectType, SAGIS_CONSTANTS } from "utils/types";
import i18n from "i18next";
import axios from "axios";
import qs from 'qs';

class MapObjectService extends ApiService {

  public async exportMapObject(requestBody) {
      const response = await axios.post(`${process.env.REACT_APP_SAGIS_APP_PATH}/brs-sagis/feature/search/export`, requestBody, {
        responseType: 'arraybuffer'
      });
      const excelBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(excelBlob);
      downloadLink.setAttribute('download', 'data.xlsx');

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
  }

  public async getAllMapObjects(requestBody, page?: number, size?: number) {
    const params = qs.stringify({ page, size, layerName: SAGIS_CONSTANTS.ROAD_FACILITIES, layerGroupName: SAGIS_CONSTANTS.ROAD });
    const url = `${process.env.REACT_APP_SAGIS_APP_PATH}/brs-sagis/features?${params}`;
    const res = await this.axios.post<IAllMapObjects>(url, requestBody);
    return res.data;
  }

  public getType(t: MapObjectType | number) {
    switch (t) {
      case MapObjectType.Bridge:
        return i18n.t("object:bridge");
      case MapObjectType.Tunel:
        return i18n.t("object:tunel");
      default:
        return i18n.t("object:unknown");
    }
  }
}

export default MapObjectService;

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import i18next from "i18next";
import { toast } from "react-toastify";
import { reduxService, storageService } from "..";
import { API_URL } from "../constants";
import ErrorHandler from "../helpers/errorHandler";

const axiosConfig: AxiosRequestConfig = {
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
  responseType: "blob",
};

class FileAxios {
  private axios = axios.create(axiosConfig);
  private redux = reduxService;
  private storage = storageService;
  private shouldHandleErrors: boolean = true;

  constructor() {
    this.axios.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.axios.interceptors.response.use(
      (res) => {
        if (res.status === 201 && res.data.message) {
          const success_msg = "success_messages:" + res.data.message;
          if (i18next.exists(success_msg) === true)
            toast(i18next.t(success_msg), { type: "success" });
          else
            toast(i18next.t("success_messages:default"), { type: "success" });
        }
        return res;
      },
      (error) => {
        // Unauthorized
        if (error.response?.status === 401) {
          this.redux.logout();
          this.storage.logout();
        } else if (error.response?.status === 403) {
          toast.error("İcazəniz çatmır", { position: "bottom-center" });
          return Promise.reject(error);
        }
        if (this.shouldHandleErrors === true) {
          ErrorHandler(error);
        }
        return Promise.reject(error);
      }
    );
  }

  public disableErrorHandler() {
    this.shouldHandleErrors = false;
  }

  public enableErrorHandler() {
    this.shouldHandleErrors = true;
  }

  /**
   *
   * @param url
   * @param params
   * @param disableAuthInjection should inject auth details to request
   */
  public async get<T>(
    url: string,
    params: AxiosRequestConfig["params"],
    disableAuthInjection: boolean = false
  ): Promise<AxiosResponse<T>> {
    let config: AxiosRequestConfig = { params };
    if (disableAuthInjection === false) config.headers = this.injectAuth();
    const res = await this.axios.get(url, config);
    return res;
  }
  /**
   *
   * @param url
   * @param data
   * @param headers
   * @param disableAuthInjection should inject auth details to request
   */
  public async post<T>(
    url: string,
    data: AxiosRequestConfig["data"],
    headers: AxiosRequestConfig["headers"] = {},
    disableAuthInjection: boolean = false
  ): Promise<AxiosResponse<T>> {
    if (disableAuthInjection === false)
      Object.assign(headers, this.injectAuth());
    const res = await this.axios.post(url, data, {
      headers,
    });
    return res;
  }
  /**
   *
   * @param url
   * @param data
   * @param headers
   * @param disableAuthInjection should inject auth details to request
   */
  public async put<T>(
    url: string,
    data: AxiosRequestConfig["data"],
    headers: AxiosRequestConfig["headers"] = {},
    disableAuthInjection: boolean = false
  ): Promise<AxiosResponse<T>> {
    if (disableAuthInjection === false)
      Object.assign(headers, this.injectAuth());
    const res = await this.axios.put(url, data, {
      headers,
    });
    return res;
  }
  /**
   *
   * @param url
   * @param data
   * @param headers
   * @param disableAuthInjection should inject auth details to request
   */
  public async patch<T>(
    url: string,
    data: AxiosRequestConfig["data"],
    headers: AxiosRequestConfig["headers"] = {},
    disableAuthInjection: boolean = false
  ): Promise<AxiosResponse<T>> {
    if (disableAuthInjection === false)
      Object.assign(headers, this.injectAuth());
    const res = await this.axios.patch(url, data, {
      headers,
    });
    return res;
  }
  /**
   *
   * @param url
   * @param data
   * @param disableAuthInjection should inject auth details to request
   */
  public async delete<T>(
    url: string,
    data: AxiosRequestConfig["data"],
    disableAuthInjection: boolean = false
  ): Promise<AxiosResponse<T>> {
    let config: AxiosRequestConfig = { data };
    if (disableAuthInjection === false) config.headers = this.injectAuth();
    const res = await this.axios.delete(url, config);
    return res;
  }

  private injectAuth(): Object | undefined {
    const { user } = this.redux.getState();
    let authHeader: AxiosRequestConfig["headers"];
    if (user.isLogged === true) {
      authHeader = { Authorization: `Bearer ${user.token}` };
    }
    return authHeader;
  }
}
export default FileAxios;

import { LOGIN_USER, LOGOUT_USER, UPDATE_USER } from "../constants";
import { UpdateProfile, UserState } from "../types";

export interface UserAction {
  type: string;
  payload?: UserState | UpdateProfile;
}
export function updateProfile(userData: UpdateProfile): UserAction {
  return {
    type: UPDATE_USER,
    payload: userData,
  };
}
export function login(data: UserState): UserAction {
  return {
    type: LOGIN_USER,
    payload: data,
  };
}
export function logout(): UserAction {
  return {
    type: LOGOUT_USER,
  };
}

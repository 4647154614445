import React from "react";
import { CFooter } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { version } from "../../package.json";

const TheFooter = () => {
  const { t } = useTranslation();

  return (
    <CFooter fixed={false}>
      <div>
        <a href="https://ayna.gov.az" target="_blank" rel="noopener noreferrer">
          Azərbaycan Yerüstü Nəqliyyat Agentliyi
        </a>
        <span className="ml-1">&copy; 2023</span>
      </div>
      <div className="mfs-auto">
        <span className="mr-1">{`${t("general:app_name")} v${version}`}</span>
      </div>
    </CFooter>
  );
};

export default React.memo(TheFooter);

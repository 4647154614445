import React, { PropsWithChildren, useMemo } from "react";
import { useSelector } from "react-redux";
import ReduxState from "../redux/types";
import Page403 from "../views/pages/page403/Page403";

type RequiredPermission = {
  permission: string;
};

function RoleProtectedRoute(props: PropsWithChildren<RequiredPermission>) {
  const user = useSelector((state: ReduxState) => state.user);
  const { permission } = props;
  const isAuthorized = useMemo(() => user.permissions?.includes(permission), [
    user,
    permission,
  ]);
  return isAuthorized ? <>{props.children}</> : <Page403 />;
}

export default RoleProtectedRoute;

export enum ServiceTypes {
  AuthService,
  ReduxService,
  StorageService,
  BusService,
  CarrierService,
  DriverService,
  EmployeeService,
  BranchService,
  CountryService,
  StopService,
  RouteService,
  MapObjectService,
  MonitoringService,
  ContractService,
}

export enum SchemaType {
  Add = "add",
  Edit = "edit",
}

export enum CarrierType {
  Legal = 1,
  Physical = 2,
}

export enum SeatsType {
  Row_5 = 5,
  Row_4 = 4,
}

export enum Role {
  Admin = 1,
  Manager,
  Chief,
  Operator,
  Observer,
}

export enum Branch {
  Main = 1,
}
export enum BusLines{
  IntraCityLabel = "Şəhərdaxili",
  IntraDistrictLabel = "Rayondaxili",
  IntercityLabel = "Şəhərlərarası (rayonlararası)",
  InternationalLabel = "Beynəlxalq"
}
export enum Permission {
  PERM_DASHBOARD = 1,
  PERM_BRANCH_ARCHIVE,
  PERM_BRANCH_CREATE,
  PERM_BRANCH_UPDATE,
  PERM_BRANCH_READ,
  PERM_EMPLOYEES_APPROVE,
  PERM_EMPLOYEES_ARCHIVE,
  PERM_EMPLOYEES_CREATE,
  PERM_EMPLOYEES_UPDATE,
  PERM_EMPLOYEES_READ,
  PERM_ROLE_MANAGEMENT,
  PERM_CARRIER_CREATE,
  PERM_CARRIER_UPDATE,
  PERM_CARRIER_READ,
  PERM_DRIVER_CREATE,
  PERM_DRIVER_UPDATE,
  PERM_DRIVER_READ,
  PERM_STOP_CREATE,
  PERM_STOP_UPDATE,
  PERM_STOP_READ,
  PERM_BUS_CREATE,
  PERM_BUS_UPDATE,
  PERM_BUS_READ,
  PERM_BUS_MODEL_READ,
  PERM_BUS_MODEL_MANAGEMENT,
  PERM_COUNTRY_MANAGEMENT,
  PERM_DRAFT_ROUTE_READ,
  PERM_DRAFT_ROUTE_CREATE,
  PERM_DRAFT_ROUTE_UPDATE,
  PERM_ROUTE_READ,
  PERM_ROUTE_ARCHIVE,
  PERM_MAP_OBJECT_CREATE,
  PERM_MAP_OBJECT_UPDATE,
  PERM_MAP_OBJECT_READ,
  PERM_MONITORING,
  PERM_CONTRACT_CREATE,
  PERM_CONTRACT_UPDATE,
  PERM_CONTRACT_READ,
  PERM_CARRIER_DELETE,
  PERM_BUS_DELETE,
  PERM_STOP_DELETE,
  PERM_MAP_OBJECT_DELETE,
}

export enum EmployeeStatus {
  Active = "Active",
  Archived = "Archived",
  // Pending = "Pending",
}

export enum ContractStatus {
  Active = "Active",
  Terminated = "Terminated",
}

export enum ContractAction {
  Activate = "activate",
  Terminate = "terminate",
}

export enum BranchStatus {
  Active = "Aktiv",
  Archived = "Arxivləşdirilmiş",
}

export enum EmployeeAction {
  Archive = "archive",
  Unarchive = "unarchive",
  // Accept = "accept",
  // Reject = "reject",
}

export enum BranchAction {
  Archive = "archive",
  Unarchive = "unarchive",
  // Accept = "accept",
  // Reject = "reject"
}

export enum PermissionAction {
  Allow = "allow",
  Deny = "deny",
}

export enum StopType {
  Stop = 'Dayanacaq',
  Terminal = 'Avtovağzal',
  AutoStation = 'Avtostansiya',
}
export enum BankType {
  ATB_VALUE = 'A',
  NONATB_VALUE = 'R',
  ATB_LABEL = 'ATB',
  NONATB_LABEL = 'Qeyri-ATB'
  }
  
export enum MapObjectType {
  Bridge = "Körpü",
  Tunel = "Tunel",
}

export enum BusType {
  Small = 1,
  Mid = 2,
  Large = 3,
}
export enum BusTypeText {
  Small = 'Kiçik',
  Mid = 'Orta',
  Large = 'Böyük',
}
export const SAGIS_CONSTANTS  = {
  BUS_LINES: "BusLines",
  ROAD: "Road",
  ROAD_FACILITIES: "RoadFacilities",
  BUS_STOPS: "BusStops",
  PUBLIC_TRANSPORT: "Public Transport",
  AYNA_DEPARTMENTS: "AYNA Departments",
  ADMINISTRATION: "Administration",
  NAME: "Name",
  COUNTRY: "Country",
  CITY: "City",
  ADDRESS: "Address",
  DEPARTMENT: "Department",
  TYPE: "Type",
  STATUS: "Status",
  AZ: 1068,
  EN: 1033,
  IntraCity: 'IntraCity bus lines',
  IntraDistrict: 'IntraDistrict bus lines',
  Intercity: 'Intercity bus lines',
  International: 'International bus lines'
} as const;
import { SET_SIDEBAR_STATE } from "../constants";
import { SidebarState } from "../types";

export interface SidebarAction {
  type: string;
  payload: SidebarState;
}
export default function (data: SidebarState): SidebarAction {
  return {
    type: SET_SIDEBAR_STATE,
    payload: data,
  };
}

import { ApiService } from "./types";
import {
  AddBrand,
  AddBus,
  AddModel,
  UpdateBrandDetails,
  UpdatedBusDetails,
  UpdateModelDetails,
} from "./types/requestTypes/Bus";
import {AddBusList, AddModelList, EmptyResponse } from "./types/responseTypes/Auth";
import {
  BusDetails,
  IAllBrandsAndCarriersWithoutPagination,
  IAllBrandsWithoutPagination,
  IAllBusesForExport,
  IAllBusesWithoutPagination,
  IBrand,
  IBusesForSelect,
} from "./types/responseTypes/Bus";
import ObjectToExcel from "../helpers/excel";
import { BusType } from "../types";
import i18n from "i18next";
import dayjs from "dayjs";

class BusService extends ApiService {
  public async getBuses() {
    const res = await this.axios.get<IAllBusesWithoutPagination>("/buses", {});
    return res.data;
  }

  public async getBusesForExport() {
    const res = await this.axios.get<IAllBusesForExport>("/buses/export", {});
    return res.data;
  }


  public async archiveBus(id: number): Promise<any> {
    const { data } = await this.axios.put<any>(
      `/buses/${id}/archive`,
      {}
    );
    return data;
  }

  public async unarchiveBus(id: number): Promise<any> {
    const { data } = await this.axios.put<any>(
      `/buses/${id}/unarchive`,
      {}
    );
    return data;
  }

  public async getAllBusesForNewRoute(ids: number[]) {
    if (ids) {
      const concatIds = ids.join(",");
      const res = await this.axios.get<IBusesForSelect>("/buses/brief", {
        carrier_ids: concatIds,
      });
      return res.data;
    }
  }

  public async getBus(id: number) {
    const res = await this.axios.get<BusDetails>(`/buses/${id}`, {});
    return res.data;
  }

  public async createBus(data: any) {
    const res = await this.axios.post<EmptyResponse>("/buses", data);
    return res.data;
  }
  public async getBusModel(id: number) {
    const res = await this.axios.get<any>(`/buses/model/${id}`, {});
    return res.data;
  }
  public async updateBus(id: number, data: UpdatedBusDetails): Promise<EmptyResponse> {
    const res = await this.axios.patch<EmptyResponse>(`/buses/${id}`, data);
    return res.data;
  }

  public async deleteBus(id: number) {
    const res = await this.axios.delete<EmptyResponse>(`/buses/${id}`, {});
    return res.data;
  }
  public async deleteBusModel(id: number) {
    const res = await this.axios.delete<EmptyResponse>(`/models/${id}`, {});
    return res.data;
  }

  public async updateBrand(id: number, data: UpdateBrandDetails) {
    const res = await this.axios.patch<UpdateBrandDetails>(
      `buses/brands/${id}`,
      data
    );
    return res.data;
  }

  public async updateModel(id: number, data: UpdateModelDetails): Promise<EmptyResponse> {
    const res = await this.axios.patch<EmptyResponse>(
      `buses/models/${id}`,
      data
    );
    return res.data;
  }

  public async createBrand(data: AddBrand) {
    const res = await this.axios.post<EmptyResponse>("buses/brands", data);
    return res.data;
  }

  public async createModel(data: AddModelList) {
    const res = await this.axios.post<EmptyResponse>("/buses/models", data);
    return res.data;
  }

  public async getBrands() {
    const res = await this.axios.get<IAllBrandsWithoutPagination>(
      "buses/brands",
      {}
    );
    return res.data;
  }

  public async getBrandsAndCarriers() {
    const res = await this.axios.get<IAllBrandsAndCarriersWithoutPagination>(
      "buses/brief/for-buses",
      {}
    );
    return res.data;
  }

  public async exportBusesToExcel() {
    const { data } = await this.getBusesForExport();
    data.forEach((b) => {
      b.created_at = dayjs(b.created_at).format("DD/MM/YYYY HH:mm:ss");
      b.type = this.getType(b.type as BusType);
    });
    ObjectToExcel(data, "buses");
  }

  public async exportBrandsToExcel() {
    let { data } = await this.getBrands();

    ObjectToExcel(
      data.map((d: IBrand) => {
        return { brand_name: d.brand_name, model_name: d.model_name };
      }),
      "brands_&_models"
    );
  }

  public getType(t: BusType | number) {
    switch (t) {
      case BusType.Small:
        return i18n.t("buses_page:small");
      case BusType.Mid:
        return i18n.t("buses_page:mid");
      case BusType.Large:
        return i18n.t("buses_page:large");
      default:
        return i18n.t("buses_page:unknown");
    }
  }
}

export default BusService;

import ObjectToExcel from "../helpers/excel";
import { ApiService } from "./types";
import {
  AddDriverRequest,
  EditDriverRequest,
} from "./types/requestTypes/Driver";
import { EmptyResponse } from "./types/responseTypes/Auth";
import {
  IAllDriversWithoutPagination,
  ICreateDriver,
  IGetDriver,
} from "./types/responseTypes/Driver";

class DriverService extends ApiService {
  public async exportDriversToExcel() {
    const { data } = await this.getAllDriversWithoutPagination();
    ObjectToExcel(data, "driver");
  }

  public async getAllDriversWithoutPagination() {
    const res = await this.axios.get<IAllDriversWithoutPagination>(
      "/drivers",
      {}
    );
    return res.data;
  }

  public async getDriver(id: number) {
    const res = await this.axios.get<IGetDriver>(`/drivers/${id}`, {});
    return res.data;
  }

  public async createDriver(data: AddDriverRequest) {
    const res = await this.axios.post<ICreateDriver>("/drivers", data);
    return res.data;
  }

  public async editDriver(data: EditDriverRequest) {
    const res = await this.axios.patch<EmptyResponse>("/drivers", data);
    return res.data;
  }
}

export default DriverService;

import TheContent from "./TheContent";
import TheFooter from "./TheFooter";
import TheHeader from "./Header/TheHeader";
import TheHeaderDropdown from "./Header/TheHeaderDropdown";
import TheHeaderDropdownMssg from "./Header/TheHeaderDropdownMssg";
import TheHeaderDropdownNotif from "./Header/TheHeaderDropdownNotif";
import TheHeaderDropdownTasks from "./Header/TheHeaderDropdownTasks";
import TheLayout from "./TheLayout";
import TheSidebar from "./TheSidebar";

export {
  TheContent,
  TheFooter,
  TheHeader,
  TheHeaderDropdown,
  TheHeaderDropdownMssg,
  TheHeaderDropdownNotif,
  TheHeaderDropdownTasks,
  TheLayout,
  TheSidebar,
};

import { SidebarAction } from "../actions/sidebar";
import { SET_SIDEBAR_STATE } from "../constants";
import { SidebarState } from "../types";

export const initialState: SidebarState = {
  sidebarShow: "responsive",
};

const sidebarReducer = (state = initialState, action: SidebarAction) => {
  switch (action.type) {
    case SET_SIDEBAR_STATE:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export default sidebarReducer;

import React from "react";
import import_dev_routes from "./helpers/import_dev_routes";
import i18n from "i18next";
import { PermissionMap } from "../utils/constants";

// TODO translate name in each route object, they are visible in breadcrumb

const UserGuide = React.lazy(() => import("../views/pages/general/userGuide"));
const Profile = React.lazy(() => import("../views/pages/profile/Profile"));
const Employees = React.lazy(
  () => import("../views/pages/employees/Employees")
);

const AddEmployee = React.lazy(
  () => import("../views/pages/employees/AddEmployee")
);

const Contracts = React.lazy(
  () => import("../views/pages/contracts/Contracts")
);
const AddContract = React.lazy(
  () => import("../views/pages/contracts/AddContract")
);
const Contract = React.lazy(() => import("../views/pages/contracts/Contract"));

const PermissionsManagement = React.lazy(
  () => import("../views/pages/employees/Permissions")
);
const Carriers = React.lazy(() => import("../views/pages/carriers/Carriers"));
const Carrier = React.lazy(() => import("../views/pages/carriers/Carrier"));
const Bus = React.lazy(() => import("../views/pages/buses/Bus"));

const AddCarrier = React.lazy(
  () => import("../views/pages/carriers/AddCarrier")
);
const Drivers = React.lazy(() => import("../views/pages/drivers/Drivers"));
const Driver = React.lazy(() => import("../views/pages/drivers/Driver"));
const AddDriver = React.lazy(() => import("../views/pages/drivers/AddDriver"));

const Dashboard = React.lazy(
  () => import("../views/pages/dashboard/Dashboard")
);

const Buses = React.lazy(() => import("../views/pages/buses/BusesTable"));
const Brands = React.lazy(() => import("../views/pages/buses/Brands/Brands"));
const AddBus = React.lazy(() => import("../views/pages/buses/AddBus"));
const Branches = React.lazy(() => import("../views/pages/branches/Branches"));
const Countries = React.lazy(
  () => import("../views/pages/countries/Countries")
);
const AddRoute = React.lazy(() => import("../views/pages/routes/AddRoute"));
const Routes = React.lazy(() => import("../views/pages/routes/Routes"));
const DraftRoutes = React.lazy(
  () => import("../views/pages/routes/DraftRoutes")
);
const SingleRoute = React.lazy(
  () => import("../views/pages/routes/SingleRoute")
);
const SingleDraftRoute = React.lazy(
  () => import("../views/pages/routes/SingleDraftRoute")
);
const EditDraftRoute = React.lazy(
  () => import("../views/pages/routes/EditDraftRoute")
);

const Stops = React.lazy(() => import("../views/pages/stops/Stops"));

const MapObjects = React.lazy(() => import("../views/pages/objects/Objects"));

const Monitoring = React.lazy(() => import("../views/pages/monitoring"));

let routes = [
  {
    path: "/",
    exact: true,
    name: i18n.t("dashboard_page:title_label"),
    component: Dashboard,
  },
  {
    path: "/branches",
    exact: true,
    name: i18n.t("branches_page:title_label"),
    component: Branches,
    permission: PermissionMap.PERM_BRANCH_READ,
  },
  {
    path: "/countries",
    name: i18n.t("countries_page:title_label"),
    component: Countries,
    permission: PermissionMap.PERM_COUNTRY_MANAGEMENT,
  },
  {
    path: "/employees",
    exact: true,
    name: i18n.t("employees_page:title_label"),
    component: Employees,
    permission: PermissionMap.PERM_EMPLOYEES_READ,
  },
  {
    path: "/employees/add",
    name: i18n.t("add_employee:title"),
    component: AddEmployee,
    permission: PermissionMap.PERM_EMPLOYEES_CREATE,
  },
  {
    path: "/employees/permissions_management",
    name: i18n.t("permissions_page:title_label"),
    component: PermissionsManagement,
    permission: PermissionMap.PERM_ROLE_MANAGEMENT,
  },
  {
    path: "/employees/:filter_value",
    name: i18n.t("permissions_page:title_label"),
    component: Employees,
    permission: PermissionMap.PERM_EMPLOYEES_READ,
  },
  {
    path: "/buses",
    exact: true,
    name: i18n.t("buses_page:title_label"),
    component: Buses,
    permission: PermissionMap.PERM_BUS_READ,
  },
  {
    path: "/buses/add",
    name: i18n.t("add_bus_page:title_label"),
    component: AddBus,
    permission: PermissionMap.PERM_BUS_CREATE,
  },
  {
    path: "/buses/brands",
    name: i18n.t("brands_page:title_label"),
    component: Brands,
    permission: PermissionMap.PERM_BUS_MODEL_MANAGEMENT,
  },
  {
    path: "/buses/:id",
    name: i18n.t("buses_page:title_label_info"),
    component: Bus,
    permission: PermissionMap.PERM_BUS_READ,
  },
  { path: "/profile", name: "Profile", component: Profile },
  {
    path: "/carriers",
    exact: true,
    name: i18n.t("all_carriers:title"),
    component: Carriers,
    permission: PermissionMap.PERM_CARRIER_READ,
  },
  {
    path: "/carriers/add",
    name: i18n.t("add_carrier:title"),
    component: AddCarrier,
    permission: PermissionMap.PERM_CARRIER_CREATE,
  },
  {
    path: "/carriers/:id",
    name: i18n.t("carrier:details"),
    component: Carrier,
    permission: PermissionMap.PERM_CARRIER_READ,
  },
  {
    path: "/routes",
    exact: true,
    name: i18n.t("route:route"),
    component: Routes,
    permission: PermissionMap.PERM_ROUTE_READ,
  },
  {
    path: "/drafts",
    exact: true,
    name: i18n.t("route:draft_title"),
    component: DraftRoutes,
    permission: PermissionMap.PERM_DRAFT_ROUTE_READ,
  },
  {
    path: "/drafts/:id/edit",
    name: i18n.t("route:edit"),
    exact: true,
    component: EditDraftRoute,
    permission: PermissionMap.PERM_DRAFT_ROUTE_UPDATE,
  },
  {
    path: "/drafts/:id",
    name: i18n.t("route:single_draft_title"),
    component: SingleDraftRoute,
    permission: PermissionMap.PERM_DRAFT_ROUTE_READ,
  },
  {
    path: "/routes/add",
    name: i18n.t("add_route:add_route"),
    component: AddRoute,
    permission: PermissionMap.PERM_DRAFT_ROUTE_CREATE,
  },
  {
    path: "/routes/:id",
    name: i18n.t("route:details"),
    component: SingleRoute,
    permission: PermissionMap.PERM_ROUTE_READ,
  },
  {
    path: "/drivers",
    exact: true,
    name: i18n.t("all_drivers:title"),
    component: Drivers,
    permission: PermissionMap.PERM_DRIVER_READ,
  },
  {
    path: "/drivers/add",
    name: i18n.t("add_driver:title"),
    component: AddDriver,
    permission: PermissionMap.PERM_DRIVER_CREATE,
  },
  {
    path: "/drivers/:id",
    name: i18n.t("driver:details"),
    component: Driver,
    permission: PermissionMap.PERM_DRIVER_READ,
  },
  {
    path: "/stops",
    name: i18n.t("stop:title"),
    exact: true,
    component: Stops,
    permission: PermissionMap.PERM_STOP_READ,
  },
  {
    path: "/objects",
    name: i18n.t("object:title"),
    exact: true,
    component: MapObjects,
    permission: PermissionMap.PERM_MAP_OBJECT_READ,
  },
  {
    path: "/monitoring",
    name: i18n.t("monitoring:title"),
    component: Monitoring,
    permission: PermissionMap.PERM_MONITORING,
  },
  {
    path: "/contracts",
    exact: true,
    name: i18n.t("contracts_page:title_label"),
    component: Contracts,
    permission: PermissionMap.PERM_CONTRACT_READ,
  },
  {
    path: "/contracts/add",
    name: i18n.t("add_contract_page:title_label"),
    component: AddContract,
    permission: PermissionMap.PERM_CONTRACT_CREATE,
  },
  {
    path: "/contracts/:id",
    name: i18n.t("contract_page:title_label"),
    component: Contract,
    permission: PermissionMap.PERM_CONTRACT_READ,
  },
  {
    path: "/guide",
    name: i18n.t("guide:title_label"),
    component: UserGuide,
  },
];

// Dev Helpers
if (process.env.REACT_APP_NODE_ENV === "local") {
  const dev_routes = import_dev_routes();
  routes = routes.concat(dev_routes);
}

export default routes;

import xlsx from "xlsx";
import i18n from "i18next";

const ObjectToExcel = (
  data: any[],
  entityName: string,
  translationName?: string
): void => {
  if (data.length === 0) return;
  const date = Date.now();
  const translatedHeader = {};
  if (translationName == null) translationName = entityName;
  Object.keys(data[0]).forEach((h) => {
    translatedHeader[h] = i18n.exists(translationName + ":" + h)
      ? i18n.t(translationName + ":" + h)
      : h;
  });
  data.unshift(translatedHeader);
  const ws = xlsx.utils.json_to_sheet(data, {
    skipHeader: true,
  });
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, entityName);
  xlsx.writeFile(wb, `${entityName}-${date}.xlsx`);
};

export default ObjectToExcel;

import { CContainer, CRow } from "@coreui/react";
import React from "react";

function Page403() {
  return (
    <CContainer className="d-flex justify-content-center align-items-center h-100">
      <CRow className="justify-content-center">
        <span className="clearfix">
          <h1 className="float-left display-3 mr-4">403</h1>
          <h4 className="pt-3">
            Burada olmağa icazəniz yoxdur
          </h4>
        </span>
      </CRow>
    </CContainer>
  );
}

export default Page403;

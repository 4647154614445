import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./views/pages/login/Login";
import TheLayout from "./components/TheLayout";
import ForgotPassword from "./views/pages/forgotPassword/ForgotPassword";
import Page404 from "./views/pages/page404/Page404";
import ProtectedRoute from "./components/ProtectedRoute";
import ForwardRoute from "./components/ForwardRoute";
import ResetPassword from "./views/pages/resetPassword/ResetPassword";
import "./scss/style.scss";
import ServiceFactory from "./utils";
import Loading from "./components/Loading";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const auth = ServiceFactory.getAuthService();

function App() {
  const [isLoading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    auth.startupChecks();
    setLoading(false);
  }, []);
  return isLoading ? (
    <Loading />
  ) : (
    <>
      <BrowserRouter>
        <Switch>
          <ForwardRoute exact path="/login" component={Login} />
          <ForwardRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <ForwardRoute
            exact
            path="/reset-password/:id/:token"
            component={ResetPassword}
          />
          <Route exact path="/404" component={Page404} />
          <ProtectedRoute path="/" component={TheLayout} />
        </Switch>
      </BrowserRouter>
      <ToastContainer position="bottom-center" />
    </>
  );
}

export default App;

import React, { useState } from "react";
import i18next from "../../../utils/locales/";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupAppend,
  CInputGroupText,
  CRow,
  CCardGroup,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Link, RouteComponentProps } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import ServiceFactory from "../../../utils";
const initialValues = {
  email: "",
};

const auth = ServiceFactory.getAuthService();
const ForgotPassword = (props: RouteComponentProps) => {
  const [clicked, setClick] = useState<boolean>(false);

  const onSubmit = () => {
    setClick(true);
    auth
      .sendResetPasswordToken({
        email: `${formik.values.email}@ayna.gov.az`,
      })
      .then(({ data }) => {
        //nothing
      })
      .catch(() => {
        setClick(false);
      });
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .required(i18next.t("login_page:login_alerts:email_required"))
      .matches(
        /^[a-zA-Z0-9.]*$/,
        i18next.t("login_page:login_alerts:email_policy")
      ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="5">
            <CCardGroup>
              <CCard
                className={`p-4 border ${
                  clicked ? "border-warning" : "border-info"
                }`}
              >
                <CCardBody>
                  <h1>{i18next.t("forgot_password:title")}</h1>
                  {clicked ? (
                    <p style={{ fontSize: 14 }}>
                      {i18next.t("reset_link:success")}
                    </p>
                  ) : (
                    <CForm onSubmit={formik.handleSubmit}>
                      <p className="text-muted">
                        {i18next.t("forgot_password:label")}
                      </p>
                      <CInputGroup className="mb-4">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          className="p-2"
                          id="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          type="text"
                          placeholder={i18next.t("login_page:email")}
                          autoComplete="email"
                        />
                        <CInputGroupAppend>
                          <CInputGroupText>{"@ayna.gov.az"}</CInputGroupText>
                        </CInputGroupAppend>
                      </CInputGroup>
                      <p style={{ color: "red" }}>
                        {formik.errors.email ? formik.errors.email : ""}
                      </p>
                      <CRow>
                        <CCol xs="6">
                          <CButton
                            color="primary"
                            type="submit"
                            className="px-4"
                            disabled={clicked}
                          >
                            {i18next.t("forgot_password:send")}
                          </CButton>
                        </CCol>
                        <CCol xs="6" className="text-right">
                          <Link to="/login">
                            <CButton color="link" className="px-0">
                              {i18next.t("login_page:back")}
                            </CButton>
                          </Link>
                        </CCol>
                      </CRow>
                    </CForm>
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPassword;

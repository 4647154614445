import { ApiService } from "utils/api/types";
import { IAllStops, IGetAllStops } from "utils/api/types/responseTypes/Stop";
import { StopType, SAGIS_CONSTANTS } from "utils/types";
import i18n from "i18next";
import axios from "axios";
import qs from "qs";

class StopService extends ApiService {

  public async exportStopsToExcel(requestBody) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SAGIS_APP_PATH}/brs-sagis/feature/search/export`, requestBody, {
        responseType: 'arraybuffer'
      });

      const excelBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(excelBlob);
      downloadLink.setAttribute('download', 'data.xlsx');
      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    }
  }

  public async getAllInfoStops(requestBody, page?: number, size?: number) {
    const params = qs.stringify({ page, size, layerName: SAGIS_CONSTANTS.BUS_STOPS, layerGroupName: SAGIS_CONSTANTS.PUBLIC_TRANSPORT });
    const url = `${process.env.REACT_APP_SAGIS_APP_PATH}/brs-sagis/features?${params}`;
    const res = await this.axios.post<IGetAllStops>(url ,requestBody);
    return res.data;
  }

  public async getAllStopsForNewRoute() {
    const res = await this.axios.get<IAllStops>("/stops/brief", {});
    return res.data;
  }

  public getType(t: StopType | number) {
    switch (t) {
      case StopType.Stop:
        return i18n.t("stop:stop");
      case StopType.Terminal:
        return i18n.t("stop:terminal");
      case StopType.AutoStation:
        return i18n.t("stop:autostation");
      default:
        return i18n.t("object:unknown");
    }
  }
}

export default StopService;

import { reduxService, storageService } from "..";
import { ApiService } from "./types";
import {
  CheckResetPasswordToken,
  LoginRequest,
  SendResetPasswordToken,
  ResetPassword,
  ChangePassword,
} from "./types/requestTypes/Auth";
import {
  LoginResponse,
  EmptyResponse,
  CheckResetPasswordTokenResponse,
} from "./types/responseTypes/Auth";

class AuthService extends ApiService {
  private storage = storageService;
  private redux = reduxService;

  public async login(data: LoginRequest) {
    const res = await this.axios.post<LoginResponse>(
      "/auth/login/employee",
      data
    );
    const {
      auth_token,
      role_id,
      permissions,
      id,
      branch_name,
      branch_feature_id,
      first_name,
      last_name,
    } = res.data.data.account;
    this.storage.login({
      role: role_id,
      token: auth_token,
      user_id: id,
      permissions,
      branch_name,
      branch_feature_id,
      first_name,
      last_name,
    });
    // TODO implement name
    this.redux.login({
      isLogged: true,
      role: role_id,
      token: auth_token,
      permissions,
      id,
      branch_name,
      branch_feature_id,
      first_name,
      last_name,
    });

    return res.data;
  }

  public async logout() {
    // TODO use correct type not empty response
    const res = await this.axios.post<EmptyResponse>(
      "/auth/logout/employee",
      {}
    );
    this.storage.logout();
    this.redux.logout();
    return res.data;
  }

  public async startupChecks() {
    const {
      token,
      permissions,
      role,
      user_id,
      branch_name,
      branch_feature_id,
      first_name,
      last_name,
    } = this.storage.getStartupDetails();
    if (!token) {
      return;
    }
    if (!permissions) {
      this.storage.logout();
      return;
    }
    // const res = await this.axios.post<CheckTokenResponse>(
    //   "/token",
    //   {},
    //   { Authorization: `Bearer ${token}` },
    //   true
    // );

    // TODO implement name
    this.redux.login({
      isLogged: true,
      role,
      token,
      permissions,
      id: user_id,
      branch_name,
      branch_feature_id,
      first_name,
      last_name,
    });
  }

  public async sendResetPasswordToken(data: SendResetPasswordToken) {
    const res = await this.axios.post<EmptyResponse>(
      "/auth/reset-password/employee/send-token",
      data
    );

    return res.data;
  }

  public async checkResetPasswordToken(data: CheckResetPasswordToken) {
    const res = await this.axios.post<CheckResetPasswordTokenResponse>(
      "/auth/reset-password/employee/check-token",
      data
    );

    return res.data;
  }

  public async resetPassword(data: ResetPassword) {
    const res = await this.axios.post<EmptyResponse>(
      "/auth/reset-password/employee",
      data
    );

    return res.data;
  }

  public async changePassword(data: ChangePassword) {
    const res = await this.axios.post<EmptyResponse>(
      "/auth/change-password/employee",
      data
    );

    return res.data;
  }
}
export default AuthService;

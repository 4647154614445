import CustomAxios from "../Axios";
import FileAxios from "../FileAxios";

export abstract class ApiService {
  protected axios: CustomAxios = new CustomAxios();
  protected fileAxios: FileAxios = new FileAxios();
  protected fileDownload = require("js-file-download");
  protected enableErrorHandler(): void {
    this.axios.enableErrorHandler();
    this.fileAxios.enableErrorHandler();
  }
  protected disableErrorHandler(): void {
    this.axios.disableErrorHandler();
    this.fileAxios.disableErrorHandler();
  }
}

export interface SuccessResponse {
  status: "success";
}

export interface IPagination {
  total: number;
  lastPage: number;
}

export interface Toast {
  position: string;
  autohide?: number | boolean;
  closeButton?: boolean;
  fade?: boolean;
  body: string;
  title: string;
}

export interface ErrorResponse {
  status: "fail" | "auth_fail" | "internal_fail";
}
